<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="650px">
			<template #modal-title>
				Translate Extended Preference
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<TextField
								:value="extendedPreferenceToTranslate.languageName"
								label="Language"
								disabled
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								label="Field Label Text"
								:value="extendedPreferenceToTranslate.defaultFieldLabel"
								disabled
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="extendedPreferenceKeyLabelTranslation"
								label="Field Label Translation"
								:disabled="!userFullPermissions"
								:rules="{max: 200}"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import Modal from '../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'

export default {
	components: {
		Modal,
		ValidationForm,
		TextField,
		IconButton,
		SecondaryActionButton,
		PrimaryActionButton
	},
	props: {
		userFullPermissions: Boolean,
		extendedPreferenceToTranslate: Object
	},
	data () {
		return {
			extendedPreferenceKeyLabelTranslation: this.extendedPreferenceToTranslate.extendedPreferenceKeyLabel
		}
	},
	computed: {
		isEdit () {
			return !!this.extendedPreferenceToTranslate.extendedPreferenceKeyLabel
		}
	},
	methods: {
		submit () {
			const extendedPreference = {
				...this.extendedPreferenceToTranslate,
				extendedPreferenceKeyLabel: this.extendedPreferenceKeyLabelTranslation
			}
			this.$emit('submit', extendedPreference)
			this.$emit('close')
		}
	}
}
</script>
