<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<ViewLayout>
			<template #header-title>
				{{ `Link Translations - ${linkTranslation.linkName} - ${language.text}` | useLabels }}
			</template>
			<template #header-caption>
				{{ 'Add, edit and manage your link.' | useLabels }}
			</template>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="linkTranslation.linkName"
									label="Link Name"
									disabled
								/>
							</v-col>
							<v-col cols="6">
								<TextField
									v-model="linkTranslation.brandName"
									label="Brand"
									disabled
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<TextField
									:value="language.text"
									label="Language"
									disabled
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard v-if="linkAuthType !== DIRECT_AUTHENTICATION_TYPE">
					<template #title>
						Authentication Page Translations
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									:value="linkTranslation.authenticationDetails.verificationAreaText"
									label="Default Authentication Area Text"
									disabled
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedVerificationAreaText"
									label="Authentication Area Text Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 2000 }"
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
						<v-row
							v-if="linkAuthType === PROVE_EMAIL_AUTHENTICATION_TYPE"
							dense
						>
							<v-col cols="6">
								<Textarea
									:value="linkTranslation.authenticationDetails.verificationSentMessage"
									label="Default Success Message"
									disabled
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
							<v-col
								cols="6"
							>
								<Textarea
									v-model="linkTranslation.translatedVerificationSentMessage"
									label="Success Message Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 2000 }"
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
						<v-row
							v-if="linkAuthType !== DIRECT_AUTHENTICATION_TYPE"
							dense
						>
							<v-col cols="6">
								<Textarea
									:value="linkTranslation.authenticationDetails.verificationFailedMessage"
									label="Default Failure Message"
									disabled
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
							<v-col
								cols="6"
							>
								<Textarea
									v-model="linkTranslation.translatedVerificationFailedMessage"
									label="Failure Message Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 2000 }"
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									:value="linkTranslation.authenticationDetails.standDakAuthFieldFriendlyName"
									label="Default Value Label Field"
									disabled
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
							<v-col
								cols="6"
							>
								<Textarea
									v-model="linkTranslation.translatedStandDakAuthFieldFriendlyName"
									label="Value Label Field Translation"
									:rules="{ max: 50 }"
									:disabled="!userFullPermissions"
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
						<v-row
							v-if="linkAuthType === PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE"
							dense
						>
							<v-col cols="6">
								<Textarea
									:value="linkTranslation.authenticationDetails.verificationSentMessage"
									label="Default Authentication Email Sent 'Success' Message"
									disabled
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
							<v-col
								cols="6"
							>
								<Textarea
									v-model="linkTranslation.translatedVerificationSentMessage"
									label="Authentication Email Sent 'Success' Message Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 2000 }"
									rows="1"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard v-if="linkType === linkTypes.preferencePageLink">
					<template #title>
						Preference Page Translations
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									v-model="defaultPreferencePageContents.pageTitle"
									label="Default Page Title"
									disabled
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedPageTitle"
									label="Page Title Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 150 }"
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									v-model="defaultPreferencePageContents.pageText"
									label="Default Page Text Translation"
									disabled
									rows="5"
									auto-grow
									dense
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedPageText"
									label="Page Text Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 2000 }"
									rows="5"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									v-model="defaultPreferencePageContents.mainPreferencesAreaTitle"
									label="Default Preference Area Title"
									disabled
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedGroupName"
									label="Preference Area Title Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 50 }"
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									v-model="defaultPreferencePageContents.mainPreferencesAreaText"
									label="Default Preference Area Text"
									disabled
									rows="5"
									auto-grow
									dense
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedGroupDescription"
									label="Preference Area Text"
									:disabled="!userFullPermissions"
									:rules="{ max: 150 }"
									rows="5"
									auto-grow
									dense
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									v-model="defaultPreferencePageContents.subPreferenceArea1title"
									label="Default Sub Area 1 Title"
									disabled
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedSubGroupLeft"
									label="Sub Area 1 Title Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 50 }"
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Textarea
									v-model="defaultPreferencePageContents.subPreferenceArea2title"
									label="Default Sub Area 2 Title"
									disabled
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
							<v-col cols="6">
								<Textarea
									v-model="linkTranslation.translatedSubGroupRight"
									label="Sub Area 2 Title Translation"
									:disabled="!userFullPermissions"
									:rules="{ max: 50 }"
									rows="1"
									dense
									auto-grow
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard v-if="linkType === linkTypes.preferencePageLink && linkTranslation.extendedPreferenceTranslations.length >= 1">
					<template #title>
						Extended Preference Translations
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="12">
								<DataTable
									:headers="tableHeaders"
									:items="linkTranslation.extendedPreferenceTranslations"
									@click:row="manageExtendedPreferenceTranslation"
								>
									<template #item.action="{ item }">
										<IconButton
											v-if="userFullPermissions"
											@click="manageExtendedPreferenceTranslation(item)"
										>
											{{ !userFullPermissions ? 'mdi-eye' : 'mdi-pencil' }}
										</IconButton>
									</template>
								</DataTable>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
		<LinkTranslationExtendedPreferenceModal
			v-if="extendedPreferenceToEdit"
			:extended-preference-to-translate="extendedPreferenceToEdit"
			:user-full-permissions="userFullPermissions"
			@close="extendedPreferenceToEdit = null"
			@submit="updateExtendedPreferenceTranslation"
		/>
	</ValidationForm>
</template>
<script>
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import LinkTranslationExtendedPreferenceModal from './link-translation-extended-preference-modal.vue'
import { linkTypes } from './link-types.js'
import {
	DIRECT_AUTHENTICATION_TYPE,
	PROVE_EMAIL_AUTHENTICATION_TYPE,
	PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE
} from '../link-authentication-types.js'
import { upsertLinkTranslation } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { PREFERENCE_PAGE_LINKS, ACTION_LINKS } from '../../../../router/route-names.js'
export default {
	components: {
		ValidationForm,
		ViewLayout,
		SectionCard,
		TextField,
		Textarea,
		DataTable,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		IconButton,
		LinkTranslationExtendedPreferenceModal
	},
	props: {
		userFullPermissions: Boolean,
		language: Object,
		linkTranslationToEdit: Object,
		linkType: Number,
		linkAuthType: Number
	},
	setup () {
		return {
			linkTypes,
			DIRECT_AUTHENTICATION_TYPE,
			PROVE_EMAIL_AUTHENTICATION_TYPE,
			PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE,
			showSnackbar
		}
	},
	data () {
		return {
			linkTranslation: JSON.parse(JSON.stringify(this.linkTranslationToEdit || {
				translatedVerificationAreaText: null,
				translatedVerificationSentMessage: null,
				translatedVerificationFailedMessage: null,
				translatedValueLabelField: null,
				translatedAuthenticationEmailSentSuccessMessage: null,
				translatedStandDakAuthFieldFriendlyName: null,
				translatedPageTitle: null,
				translatedPageText: null,
				translatedGroupName: null,
				translatedGroupDescription: null,
				translatedSubGroupLeft: null,
				translatedSubGroupRight: null,
				extendedPreferenceTranslations: []
			})),
			extendedPreferenceToEdit: null
		}
	},
	computed: {
		tableHeaders () {
			return [
				{
					text: 'Channel',
					value: 'channelName'
				},
				{
					text: 'Extended Preference',
					value: 'keyName'
				},
				{
					text: 'Default Field Label',
					value: 'defaultFieldLabel'
				},
				{
					text: 'Translated Field Label',
					value: 'extendedPreferenceKeyLabel'
				},
				{
					text: 'Action',
					value: 'action'
				}
			]
		},
		isEdit () {
			return !!this.linkTranslationToEdit.linkTranslationId
		},
		defaultPreferencePageContents () {
			return this.linkTranslation.preferencePageLinkConfig.pageContents
		}
	},
	methods: {
		backToOverview () {
			if (this.linkType === linkTypes.preferencePageLink) {
				this.$router.push({
					name: PREFERENCE_PAGE_LINKS,
					params: {
						accessedViaTranslations: true
					}
				})
			} else {
				this.$router.push({
					name: ACTION_LINKS,
					params: {
						accessedViaTranslations: true
					}
				})
			}
		},
		manageExtendedPreferenceTranslation (extendedPreference) {
			if (!this.userFullPermissions) return
			this.extendedPreferenceToEdit = extendedPreference
		},
		updateExtendedPreferenceTranslation (extendedPreferenceTranslation) {
			this.$set(this.linkTranslation.extendedPreferenceTranslations, extendedPreferenceTranslation.index, extendedPreferenceTranslation)
			this.extendedPreferenceToEdit = null
		},
		async submit () {
			const linkTranslation = {
				linkTypeId: this.linkType,
				linkId: this.linkTranslation.linkId,
				languageId: this.language.value,
				verificationAreaText: this.isEmpty(this.linkTranslation.translatedVerificationAreaText),
				verificationSentMessage: this.isEmpty(this.linkTranslation.translatedVerificationSentMessage),
				verificationFailedMessage: this.isEmpty(this.linkTranslation.translatedVerificationFailedMessage),
				verificationConfirmedMessage: this.isEmpty(this.linkTranslation.translatedVerificationConfirmedMessage),
				standDakAuthFieldFriendlyName: this.isEmpty(this.linkTranslation.translatedStandDakAuthFieldFriendlyName),
				pageTitle: this.isEmpty(this.linkTranslation.translatedPageTitle),
				pageText: this.isEmpty(this.linkTranslation.translatedPageText),
				groupName: this.isEmpty(this.linkTranslation.translatedGroupName),
				groupDescription: this.isEmpty(this.linkTranslation.translatedGroupDescription),
				subGroupLeft: this.isEmpty(this.linkTranslation.translatedSubGroupLeft),
				subGroupRight: this.isEmpty(this.linkTranslation.translatedSubGroupRight),
				extendedPreferenceTranslations: this.linkTranslation.extendedPreferenceTranslations?.map(extendedPreferenceTranslation => ({
					preferenceUpdatePageStatementsId: extendedPreferenceTranslation.preferenceUpdatePageStatementsId,
					languageId: extendedPreferenceTranslation.languageId,
					extendedPreferenceKeyId: extendedPreferenceTranslation.extendedPreferenceKeyId,
					extendedPreferenceKeyLabel: extendedPreferenceTranslation.extendedPreferenceKeyLabel,
					pupsepId: extendedPreferenceTranslation.pupsepId
				}))
			}
			await upsertLinkTranslation(linkTranslation)
			showSnackbar('Link translation saved successfully')
			this.backToOverview()
		},
		isEmpty (value) {
			if (value === null || value === undefined || value === '') {
				return null
			}
			return value
		}
	}
}

</script>
